/* ====================================================
Recreating the email field from https://webflow.com/cms. Just an experiment - not as cross-browser friendly as the original.
Changed:
- animated gradient bar to :after element
- flexbox for layout
==================================================== */

// reset
html {
  box-sizing: border-box;
  font-size: 10px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body,
ul,
li {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
a {
  text-decoration: none;
}
input {
  border-style: none;
  background: transparent;
  outline: none;
}
button {
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

// some basic page styles
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-image: radial-gradient(
    circle at 0% 0%,
    #373b52,
    #252736 51%,
    #1d1e26
  );
}
// for demo
.laro-flex-spacer {
  flex-grow: 1;
}
.container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  height: 100vh;
  max-width: 1600px;
  padding: 0 15px;
  margin: 0 auto;
}
// colors
$input-background: rgba(57, 63, 84, 0.8);
$input-text-inactive: #7881a1;
$input-text-active: #bfd2ff;
// gradient animation
@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.webflow-style-input {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 2px;
  padding: 1.4rem 2rem 1.6rem;
  background: $input-background;
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    height: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-position: 0% 0%;
    background: linear-gradient(
      to right,
      #b294ff,
      #57e6e6,
      #feffb8,
      #57e6e6,
      #b294ff,
      #57e6e6
    );
    background-size: 500% auto;
    animation: gradient 3s linear infinite;
  }
}
.webflow-style-input input {
  flex-grow: 1;
  color: $input-text-active;
  font-size: 1.8rem;
  line-height: 2.4rem;
  vertical-align: middle;
  &::-webkit-input-placeholder {
    color: $input-text-inactive;
  }
}
.webflow-style-input button {
  color: $input-text-inactive;
  font-size: 2.4rem;
  line-height: 2.4rem;
  vertical-align: middle;
  transition: color 0.25s;
  &:hover {
    color: $input-text-active;
  }
}
.card {
  background-color: #35394d;
}
.single-message {
  font-size: 17px;
  span {
    color: $input-text-active;
  }
  strong {
    color: $input-text-inactive;
  }
}
.statusMessage {
  text-align: center;
  color: $input-text-active;
  display: block;
  max-width: 100%;
  width: 100%;
  // margin-top: 5px;
}
#clear {
  padding: 1rem 2rem;
  font-size: 1.7rem;
}
